.navbar-collapse {
    flex-grow: 0 !important;
}
.isCurrent .nav-link {
  color: #fff !important;
}
.bg-24{
  background-color: #242424 !important;
}
.head{
    background: url(./Components/img/banner.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0%;
    margin-left: -2px;
    margin-top: 46px;
}
.photoBorder{
  position: relative;
}
.photoBorder::after{
  position: absolute;
  right: -18px;
  bottom: -18px;  
  height: 26%;
  width: 45%;
  border-right: 5px solid #242424;
  border-bottom: 5px solid #242424;
  content: "";
  z-index: -1;
  animation: moveup 2s linear;
}
.btn-border{
  position: relative;
}
.btn-border::after{
  position: absolute;
  right: 0px;
  bottom: 0px;  
  height: 55%;
  width: 30%;
  border-right: 2px solid white;
  border-bottom: 2px solid white;
  content: "";
  transition: 0.5s linear;
}
.btn-border::before {
  position: absolute;
  left: 0px;
  top: 0px;
  height: 55%;
  width: 30%;
  border-left: 2px solid white;
  border-top: 2px solid white;
  content: "";
  transition: 0.5s linear;
}
.btn-border:hover::after,  .btn-border:hover::before{
  height: 100%;
  width: 100%;
}
.btn-check:focus + .btn, .btn:focus {
  box-shadow: none !important;
}
@keyframes moveup{
  100%{
    right: -18px;
    bottom: -18px;    
  }
  0%{
    right: -180px;
  bottom: -180px;
  }
}
.photoBorder::before {
  position: absolute;
  left: -18px;
  top: -18px;
  height: 26%;
  width: 45%;
  border-left: 5px solid #242424;
  border-top: 5px solid #242424;
  content: "";
  z-index: -1;
  animation: movedown 2s linear;
}
@keyframes movedown{
  100%{
    left: -18px;
  top: -18px;   
  }
  0%{
    left: -180px;
  top: -180px;
  }
}
.contact{
    background: url(./Components/img/Vector\ 3.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin-bottom: -3px;
}
.education {
    position: relative;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  
  }
  .education {
    background-image: linear-gradient(to right,rgba(244, 244, 244, 0.7),rgba(244, 244, 244,0.4)), url("https://68.media.tumblr.com/d3fd545cd977a174beef12c1106880bf/tumblr_nt505iKkul1ud7rr3o1_1280.jpg");
    min-height: 400px;
  } 
  .p_bar {
    height:10px;
    width:500px;
    background:rgba(0, 0, 0, 0.1);
    margin:0 auto;
    border-radius: 10px;
    box-sizing: border-box;
    position:relative;
    margin:30px 70px; 
  }
  
  div.w_bar {
    min-height:8;
    height:10px;
    width:0;
    background:black;
    border-radius:10px;
    bottom:0px;
    opacity:1;
  }
  
  .per {    
    color:#000;
    font-size: 18px;
    font-weight:700;
  }
  .border-black{
    border: 2px solid black;
    height: 80px;
    width: 80px;
  }
  .icon{
    font-size: 45px;
  }
  .border-black:hover{
    transform: rotate(45deg);
  }
  .border-black:hover > .icon{
    transform: rotate(-45deg);
  }
  @media (min-width: 500px) {
      .my-10{
        margin-top: 9rem !important;
        margin-bottom: 14rem;
    }
  }
  @media (min-width: 768px) {
    .mx-md-7 {
      margin-right: 7rem !important;
      margin-left: 7rem !important;
    }
  }
  @media (max-width: 768px) {
    .mx-md-7 {
      margin-right: 3rem !important;
      margin-left: 3rem !important;
    }
  }
  @media (max-width: 500px) {
    .my-10{
      margin-top: 2rem!important;
      margin-bottom: 4rem;
    }
  }
  @media (max-width: 1200px) {
    .head {
      background-position: 59%;
    }

  }
  @media (max-width: 1550px) {
    .con {
        padding: 0 !important;
        margin: 0 !important;
        max-width: 100% !important;
    }
  }
  